import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Loading from "../components/loading.main";
import $ from "jquery";
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exit: false,
      opacity: false,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ exit: true });
    }, 9000);
    setTimeout(() => {
      this.setState({ opacity: true });
    }, 8000);

    $("#root").css("background-color", "black");
  }
  render() {
    if (this.state.exit) return <Redirect to="/home"></Redirect>;
    return <Loading exit={this.state.exit} opacity={this.state.opacity} />;
  }
}
