import React, {Component} from 'react';
import {Link} from 'react-router-dom';
export default class ItemMenu extends Component {
    render() {
        return (
            <div className="item">
                <a onClick={this.props.onClick} href={this.props.to}>{this.props.label}</a>    
            </div>
        );
    }
}