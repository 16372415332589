import React, { Component } from "react";
import Logo from "../images/CD-Icons-02-white.png";
export default class Contact extends Component {
  render() {
    return (
      <div className="contact show">
        <div className="contact_logo"  onClick={() => window.location.href = "/home"}>
          <img src={Logo} alt="logo" />
        </div>
        <div className="contact_content">
          <div className="sub_content">
            <h1>MIAMI</h1>
            <p>667 NW 29TH ST, MIAMI, FL 33127 </p>
            <span className="email">
              <a href="mailto:INFO@CASADIOUSA.COM">INFO@CASADIOUSA.COM</a>
            </span>
          </div>
          <div className="sub_content">
            <h1>LONDON</h1>
            <p>26 DOVER ST, MAYFAIR LONDON</p>
            <span className="email">
              <a href="mailto:INFO@CASADIOLONDON.COM">INFO@CASADIOLONDON.COM</a>
            </span>
          </div>
          <h3>BY APPOINTMENT ONLY</h3>
        </div>
      </div>
    );
  }
}
