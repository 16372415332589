import React, { Component } from "react";
import Logo from "../images/CD-Icons-02-white.png";
import $ from "jquery";
export default class Loading extends Component {
  componentDidMount() {
    $(".bar").each((index, item) => {
      if ((index + 1) % 2 === 0) {
        $(item).addClass("m_hide");
      }
    });
  }
  render() {
    return (
      <div className={"loading_main " + (this.props.opacity ? "exit" : "")}>
        <div className="bar"></div>
        <div className="bar delay-1"></div>
        <div className="bar delay-4"></div>
        <div className="bar delay-3"></div>
        <div className="bar delay-5"></div>
        <div className="bar delay-2"></div>
        <div className="bar delay-4"></div>
        <div className="bar delay-7"></div>
        <div className="bar delay-11"></div>
        <div className="bar delay-2"></div>
        <div className="bar delay-1"></div>
        <div className="bar delay-4"></div>
        <div className="bar delay-3"></div>
        <div className="bar delay-5"></div>
        <div className="bar delay-4"></div>
        <div className="bar delay-2"></div>
        <div className="bar delay-6"></div>
        <div className="bar delay-1"></div>
        <div className="bar delay-3"></div>
        <div className="bar delay-13"></div>
        <div className="bar delay-7"></div>
        <div className="bar delay-4"></div>
        <div className="bar delay-2"></div>
        <div className="bar delay-5"></div>
        <div className="bar delay-3"></div>
        <div className="bar delay-1"></div>
        <div className="bar delay-2"></div>
        <div className="bar delay-6"></div>
        <div className="bar delay-2"></div>
        <div className="bar delay-3"></div>
        <div className="bar delay-7"></div>
        <div className="bar delay-4"></div>
        <div className="bar delay-2"></div>
        <div className="bar delay-5"></div>
        <div className="bar delay-12"></div>
        <div className="bar delay-4"></div>
        <div className="bar delay-1"></div>
        <div className="bar delay-3"></div>
        <div className="bar delay-4"></div>
        <div className="bar delay-7"></div>
        <div className="bar delay-14"></div>
        <div className="bar delay-2"></div>
        <div className="bar delay-1"></div>
        <div className="bar delay-3"></div>
        <div className="bar delay-6"></div>
        <div className="bar delay-2"></div>
        <div className="bar delay-7"></div>
        <div className="bar delay-5"></div>
        <div className="bar last"></div>
        <div className={"content_img "}>
          <img
            src={Logo}
            alt="Logo"
            className={this.props.opacity ? "exit" : ""}
          />
        </div>
      </div>
    );
  }
}
