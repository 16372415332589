import React, { Component } from "react";
import Logo from "../images/CD-Icons-02-white.png";
import { Section } from "../data/section";
export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      text: '',
      images: ''
    }
  }
  componentDidMount() {
    // Quote
    Section(8).then((r) => {
      if (r.status === 200) {
        let state = this.state;
        state.title = r.data.title;
        state.text = r.data.text;
        state.images =  process.env.REACT_APP_API  + "/images/sections/" + r.data.images;
        this.setState(state);
      }
    });
  }
  render() {
    return (
      <div className="about show" style={{backgroundImage: "url(" + this.state.images + ")"}}>
        <div className="about_logo" onClick={() => window.location.href = "/home"}>
          <img src={Logo} alt="logo" />
        </div>
        <div className="about_content">
          <h1>{this.state.title ?? "ABOUT US"}</h1>
          <p dangerouslySetInnerHTML={{__html: this.state.text.replace(/\n/g, "<br />")}}></p>
        </div>
      </div>
    );
  }
}
