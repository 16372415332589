import React from "react";
export default function Footer() {
  return (
    <div className="footer">
      <a
        href="https://instagram.com/casadiousa?igshid=1u7brvfd1lo2s"
        target="blank"
      >
        INSTAGRAM
      </a>
      {/* <a href="https://www.facebook.com/ww.casadio.us" target="blank">
        FACEBOOK
      </a> */}
      <a
        href="https://www.youtube.com/channel/UCE2cQtFTqf_JQbKqc8MnTHQ"
        target="blank"
      >
        YOUTUBE
      </a>
      {/* <a href="https://instagram.com">BACK TO TOP</a> */}
    </div>
  );
}
