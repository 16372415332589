import React from "react";
import { Section } from "../data/section";
export default class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoMP4: "/video/PUREONE-CD.mp4",
      videooGG: "/video/PUREONE-CD.ogg",
      videoWebm: "/video/PUREONE-CD.webm",
    };
  }
  componentDidMount() {
    // Quote
    Section(9).then((r) => {
      if (r.status === 200) {
        let state = this.state;
        state.videoMP4 = "/images/sections/" + r.data.images
        this.setState(state);
      }
    });

    // Quote
    Section(10).then((r) => {
      if (r.status === 200) {
        let state = this.state;
        state.videooGG = "/images/sections/" + r.data.images
        this.setState(state);
      }
    });

    // Quote
    Section(11).then((r) => {
      if (r.status === 200) {
        let state = this.state;
        state.videoWebm = "/images/sections/" + r.data.images
        this.setState(state);
      }
    });
  }
  render() {
    return (
      <div className="video">
        <video width={"100%"} height={"100%"} controls preload="auto">
          <source
            src={process.env.REACT_APP_API +  this.state.videoMP4}
            type="video/mp4"
          />
          <source
            src={process.env.REACT_APP_API + this.state.videooGG}
            type="video/ogg"
          />
          <source
            src={process.env.REACT_APP_API + this.state.videoWebm}
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
}
