import React, { Component } from "react";
import { Detailt } from "../data/furniture";
import Carousel from "../components/productCarousel";
import Loading from "../components/loading";
import Close from "../images/300/Icons-Web-Final-01.png";

export default class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      return: this.props.match.params.return,
      furniture: this.props.match.params.furniture,
      data: null,
      title: "",
      loading: true,
      url: "",
    };
  }
  componentDidMount() {
    window.$(".app").css("background-color", "white");
    Detailt(this.state.id).then((r) => {
      if (r.status === 200) {
        this.setState({
          loading: false,
          url: r.data.url,
          data: r.data.data,
          title: r.data.title,
        });
      }
    });
  }
  render() {
    if (this.state.loading === true) return null;
    return (
      <div className="detailt show">
        <div className="detailt_exit" onClick={() => window.history.back()}>
          <img src={Close} alt="Close" />
        </div>
        <div className="detailt_content">
          <div className="detailt_header">
            <h1>{this.state.title && this.state.title}</h1>
          </div>
          {this.state.data && (
            <Carousel
              data={this.state.data}
              url={this.state.url}
              loading={this.state.loading}
            />
          )}
          <div className="detailt_content_action">
            <div className="action">PRODUCT INFORMATION</div>
            <div className="action">SPEC SHEET</div>
          </div>
        </div>
      </div>
    );
  }
}
