import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import Arrow from "../images/300/CD-Icons-03.png";
import $ from "jquery";
export default class Carousel extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    //  window.$(".carousel").carousel();
    if (this.props.data.length <= 1) {
      $(".iprev").hide();
      $(".inext").hide();
    }
  }
  componentWillReceiveProps(prev) {
    if (prev.loading === false) {
      if (prev.data.length <= 1) {
        $(".iprev").hide();
        $(".inext").hide();
      }
    }
  }
  render() {
    return (
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-interval="false"
      >
        <ol className="carousel-indicators">
          {this.props.data &&
            this.props.data.map((item, index) => {
              if (isMobile && item.view_movil == 0) return null;
              return (
                <li
                  key={index}
                  data-target="#carouselExampleIndicators"
                  data-slide-to={index}
                  className={index === 0 ? "active" : ""}
                />
              );
            })}
        </ol>
        <div className="carousel-inner">
          {this.props.data &&
            this.props.data.map((item, index) => {
              if (isMobile && item.view_movil == 0) return null;
              return (
                <div
                  className={"carousel-item " + (index == 0 ? "active" : "")}
                  key={index}
                >
                  <div className="item_images">
                    <img
                      className={"d-block " + (item.zoom == 1 ? "zoom" : "")}
                      src={this.props.url + item.images}
                      alt={"Img" + index}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <a
          className="carousel-control-prev iprev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <div className="arrow_left">
            <img src={Arrow} alt="arrow" />
          </div>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next inext"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <div className="arrow_left">
            <img src={Arrow} alt="arrow" />
          </div>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}
