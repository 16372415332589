import React from 'react';
import Layout from './components/layout';
import Main from './views/main';
import Home from './views/home';
import About from './views/about';
import Contact from './views/contact';
import Project from './views/projects';
import Furniture from './views/furniture';
import FurnitureDetailt from './views/detailt';
import FurnitureFullScreen from './views/detailtFull';

const route = [
    { path: "/", exact: true, component: () => <Main /> },
    { path: "/home/:area?", exact: true, component: (props) => <Layout><Home {...props} /></Layout> },
    { path: "/about", exact: true, component: () => <Layout><About /></Layout> },
    { path: "/contact", exact: true, component: () => <Layout><Contact /></Layout> },
    { path: "/projects/:return/:id", exact: true, component: (props) => <Layout><Project {...props} /></Layout> },
    { path: "/furniture/:id", exact: true, component: (props) => <Layout><Furniture {...props} /></Layout> },
    { path: "/furniture/detailt/:furniture/:id", exact: true, component: (props) => <Layout><FurnitureFullScreen {...props} /></Layout> },
    { path: "/furniture/test/:furniture/:id", exact: true, component: (props) => <Layout><FurnitureFullScreen {...props} /></Layout> },
]

export default route;