import axios from 'axios';
export function Menu() {
    return axios.get(process.env.REACT_APP_API + "/api/category/getall.php?active=true");
}

export function Furniture(id) {
    return axios.get(process.env.REACT_APP_API + "/api/furniture/getbycategory.php?category=" + id);
}

export function Detailt(id) {
    return axios.get(process.env.REACT_APP_API + "/api/furniture/getimages.php?id=" + id);
}