import React, { Component } from "react";
import $ from "jquery";
import { GetImages } from "../data/projects";
import Carousel from "../components/carousel2";
import Close from "../images/Icons-Web-Final-08.png";
import Loading from "../components/loading";

function imagesLoaded() {
  const imgElements = $(".project_img_single");
  for (const img of imgElements) {
    if (img.naturalWidth > img.naturalHeight) {
      $(img).addClass("full");
    }
    if (!img.complete) {
      return false;
    }
  }
  return true;
}

export default class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      return: this.props.match.params.return,
      data: null,
      title: "",
      loading: true,
      url: "",
    };
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  handleImageChange() {
    let finish = imagesLoaded();
    if (finish) {
      setTimeout(() => {
        this.setState({
          loading: !finish,
        });
        $(".project").addClass("show");
      }, 4000);
    }
  }

  componentDidMount() {
    GetImages(this.state.id).then((r) => {
      if (r.status === 200) {
        setTimeout(() => {
          this.setState({
            loading: false,
          });
          $(".project").addClass("show");
        }, 3000);
        this.setState({
          url: r.data.url,
          data: r.data.data,
        });
      }
    });
  }
  render() {
    return (
      <div className="project">
        {this.state.loading && <Loading />}
        <div
          className="project_exit"
          onClick={() => window.open("/home/" + this.state.return, "_self")}
        >
          <img src={Close} alt="Close" />
        </div>
        <Carousel
          onLoad={this.handleImageChange}
          data={this.state.data}
          url={this.state.url}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
