import React, { Component } from "react";
import Arrow from "../images/300/CD-Icons-03.png";
import { isMobile } from "react-device-detect";
export default class Carousel extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(prev) {
    if (prev.loading === true) {
      setTimeout(() => {
        var imagesElements = document.getElementsByClassName("carousel-item");
        for (var index = 0; index < imagesElements.length; index++) {
          if (index > 0) {
            imagesElements[index].classList.toggle("active");
          }
        }
      }, 2500);
    }
  }
  render() {
    return (
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride={this.props.loading ? "false" : "carousel"}
        data-interval="5000"
        data-pause="off"
      >
        <ol className="carousel-indicators">
          {this.props.data &&
            this.props.data.map((item, index) => {
              if (isMobile && item.view_movil === 0) return null;
              return (
                <li
                  key={index}
                  data-target="#carouselExampleIndicators"
                  data-slide-to={index}
                  className={index === 0 ? "active" : ""}
                />
              );
            })}
        </ol>
        <div className="carousel-inner">
          {this.props.data &&
            this.props.data.map((item, index) => {
              if (isMobile && item.view_movil == 0) return null;
              return (
                <div
                  className={
                    "carousel-item " + (index === 0 ? "active" : "active")
                  }
                  style={{
                    backgroundImage:
                      "url('" + this.props.url + item.images + "')",
                  }}
                  key={index}
                />
              );
            })}
        </div>
        <a
          className="carousel-control-prev iprev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <div className="arrow_left">
            <img src={Arrow} alt="arrow" />
          </div>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next inext"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <div className="arrow_left">
            <img src={Arrow} alt="arrow" />
          </div>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}
