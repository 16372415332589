import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import router from './router';
import './app.scss';
import './responsive.css';
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          {
            router && router.map((item, index) => {
              return <Route
                exact={item.exact}
                key={index}
                path={item.path}
                render={(props) => (
                  <item.component {...props} />
                )}
              ></Route>
            })
          }
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
